<template>
  <div id="app">
    <div id="create-room-modal" class="modal" v-show="roomModal">
      <div class="modal-content">
        <span class="close-btn" @click="roomModal ? roomModal = false : roomModal = true">&times;</span>
        <p>请输入你要创建或进入的房间名称:</p>
        <input type="text" id="new-room-name" v-model="roomname" placeholder="房间名称，仅限于字母和数字组合">
        <button id="create-room-confirm-btn" @click="create()">确定</button>
      </div>
    </div>
    <div id="create-room-modal" class="modal" v-show="qrcodeModal">
      <div class="modal-content">
        <span class="close-btn" @click="qrcodeModal ? qrcodeModal = false : qrcodeModal = true">&times;</span>

        <QRCodeVue3 :width="200" :height="200" :value="currentURL"
          :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
          :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 0 }" :dotsOptions="{
      type: 'dots',
      color: '#26249a',
      gradient: {
        type: 'linear',
        rotation: 0,
        colorStops: [
          { offset: 0, color: '#26249a' },
          { offset: 1, color: '#26249a' },
        ],
      },
    }" :backgroundOptions="{ color: '#ffffff' }" :cornersSquareOptions="{ type: 'dot', color: '#000000' }"
          :cornersDotOptions="{ type: undefined, color: '#000000' }" fileExt="png" :download="true" myclass="my-qur"
          imgclass="img-qr" downloadButton="my-button" :downloadOptions="{ name: 'vqr', extension: 'png' }" />
      </div>
    </div>
    <div class="image-container" v-show="previewImg" @click="previewImg = ''">
      <img :src="previewImg" alt="Descriptive Text">
    </div>
    <div class="chat-container" :style="!isUsernameSet || !isPinSet ? 'height:100vh' : ''">
      <div style="margin-bottom: 0px;display: block;font-size: 11px;margin-left: 10px;font-weight: 400;color: #419fd9; "
        v-html="room ? '当前房间 : ' + room + '&nbsp;' + ms + ' ms' : '当前房间 ：综合区' + '&nbsp;' + ms + ' ms'">
      </div>
      <h1 style="margin-bottom: 10px;padding:0 10px;display: flex;">
        <img src="./assets/logo.png" style="height:50px;" />

        <div class="right">
          <button @click="showDialog = true" style="margin-right: 10px;" title="在线人数">
            <i class="fa-solid fa-user-large"></i>{{
      onlineUsers.length }}
          </button>
          <button style="margin-right: 10px;" v-if="isUsernameSet && isPinSet" @click="reName" class="rename"
            title="重新设置用户名"><i class="fa-solid fa-cookie"></i> </button>
          <button style="margin-right: 10px;" v-if="room" title="退出房间"> <a href="/" style="color:white"><i
                class="fa-solid fa-door-open"></i></a></button>
          <button style="margin-right: 10px;" :class="room ? 'hiddenCreate' : ''"
            @click="roomModal ? roomModal = false : roomModal = true" :disabled="room" title="创建房间">
            <i class="fa-solid fa-plus"></i>
          </button>
          <button style="margin-right: 10px;" title="分享二维码"
            @click="qrcodeModal == false ? qrcodeModal = true : qrcodeModal = false"><i
              class="fa-solid fa-qrcode"></i></button>

        </div>
      </h1>

      <!-- Dialog -->
      <div :class="showDialog ? 'online-users-dialog active' : 'online-users-dialog'">
        <h2>活跃用户列表(最多显示10个)</h2>
        <table>
          <thead>
            <tr>
              <th>用户名</th>
              <th>最后在线时间</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in onlineUsers.slice(0, 10)" :key="user.username">
              <td>{{ user.username }}</td>
              <td>{{ user.last_active }}</td>
            </tr>
          </tbody>
        </table>
        <!-- Button to close the dialog -->
        <button @click="showDialog = false">关闭</button>
      </div>
      <div class="messages" ref="messages" @scroll="handleScroll">
        <div v-for="msg in messages" :key="msg.id" class="message" :class="{ 'admin': msg.is_admin }">
          <div v-if="msg.username != username" class="left-msg">
            <div class="msg-bubble">
              <div class="msg-info">
                <div class="msg-info-name" v-html="msg.is_admin == true ? '👨🏻‍💼 ' + msg.username : msg.username"
                  :style="msg.is_admin == 'true' ? 'color:red' : ''"></div>
                <div class="msg-info-time">{{ formatDate(msg.timestamp) }}</div>
              </div>
              <div class="msg-text" v-html="md.render(msg.message)">
              </div>
            </div>
          </div>
          <div class="right-msg">
            <div v-if="msg.username == username" class="msg-bubble">
              <div class="msg-info">
                <div class="msg-info-name" v-html="msg.is_admin == true ? '👨🏻‍💼 ' + msg.username : msg.username"
                  :style="msg.is_admin == 'true' ? 'color:red' : ''"></div>
                <div class="msg-info-time">{{ formatDate(msg.timestamp) }}</div>
              </div>

              <div class="msg-text" v-html="md.render(msg.message)"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="input-area">
        <input v-if="!isUsernameSet" v-model="username" placeholder="输入你的昵称" style="margin: 10px 20px" />
        <input v-if="isUsernameSet && !isPinSet" v-model="pin" placeholder="输入你的6位唯一PIN码" style="margin:10px  20px" />
        <!--
        <div v-if="isUsernameSet && isPinSet" class="talk-action">
          <div v-if="isUsernameSet && isPinSet" class="emoji-toggle" @click="toggleEmojiPicker">😊</div>
          <div v-if="isUsernameSet && isPinSet" class="image-toggle" @click="toggleImage">🖼️</div>
          <div v-if="isUsernameSet && isPinSet" class="link-toggle" @click="toggleLink">🔗</div>
        </div>
-->

        <!-- <textarea id="myTextarea" v-if="isUsernameSet && isPinSet" v-model="message" @click="emojiDisplay = false"
          ref="textarea" placeholder="随便说点什么..."></textarea> -->
        <div v-if="isUsernameSet && isPinSet && emojiDisplay" @emoji-click="addEmoji">
          <emoji-picker id="emoji"></emoji-picker>
        </div>

        <button v-if="!isUsernameSet" @click="setUsername" style="margin: 0px 20px">设置昵称</button>
        <button v-if="isUsernameSet && !isPinSet" @click="checkPin" style="margin: 0px 20px">设置PIN码</button>
        <!--
        <button v-if="isUsernameSet && isPinSet" @click="sendMessage">发送</button> -->
        <div class="chat-footer" v-if="isUsernameSet && isPinSet">
          <button id="emoji-btn" v-if="isUsernameSet && isPinSet" class="emoji-toggle" @click="toggleEmojiPicker"><i
              class="fa-solid fa-face-smile"></i></button>
          <button id="image-btn" v-if="isUsernameSet && isPinSet" class="image-toggle" @click="toggleImage"><i
              class="fa-solid fa-image"></i></button>
          <button id="link-btn" v-if="isUsernameSet && isPinSet" class="link-toggle" @click="toggleLink"><i
              class="fa-solid fa-link"></i></button>
          <input id="myTextarea" v-if="isUsernameSet && isPinSet" v-model="message" @click="emojiDisplay = false"
            ref="textarea" placeholder="随便说点什么...">
          <button v-if="isUsernameSet && isPinSet" @click="sendMessage" id="send-btn">发送</button>

        </div>
        <p class="notify" v-if="isUsernameSet && isPinSet"><i class="fa-solid fa-circle-exclamation"></i>&nbsp;{{
      $query.title
          }}提醒您，请自觉遵守聊天室的发言规范，不要发送任何个人隐私信息</p>
        <!---
        <a class="bottomAction">

          <a v-if="isUsernameSet && isPinSet" @click="reName" class="rename">重新设置用户名</a>
          <a v-if="room" href="/">退出房间</a>

        </a>
                  -->

      </div>
    </div>
    <router-view></router-view>

  </div>

</template>

<script>
import axios from 'axios';
import MarkdownIt from 'markdown-it'
import QRCodeVue3 from "qrcode-vue3";
export default {
  name: 'QRCodeVue3Example',
  components: {
    QRCodeVue3
  },
  data() {

    return {
      username: '',
      pin: '',
      message: '',
      messages: [],
      isUsernameSet: false,
      isPinSet: false,
      isLoading: false,
      currentPage: 1,
      perPage: 50,
      allMessagesLoaded: false,
      onlineUsers: [],
      isAdmin: false,
      showDialog: false,
      emojiDisplay: false,
      send: false,
      newScroll: false,
      ms: '0',
      requestTimer: 0,
      md: new MarkdownIt(),
      previewImg: '',
      lastScrollTop: 0,
      room: '',
      roomModal: false,
      roomname: '',
      qrcodeModal: false,
      eventSource: false,
    };
  },
  computed: {
    currentURL() {
      return window.location.href;
    }
  },
  mounted() {
    document.title = this.$query.title;
    /* eslint-disable */
    (() => {
      function block() {
        if (
          window.outerHeight - window.innerHeight > 200 ||
          window.outerWidth - window.innerWidth > 200
        ) {
          document.body.innerHTML =
            "检测到非法调试,请关闭后刷新重试!";
          s

        }
        setInterval(() => {
          (function () {

            return false;
          }
          ["constructor"]("debugger")["call"]());
        }, 50);
      }
      try {
        block();
      } catch (err) { }
    })();
    /* eslint-enable */
    this.fetchOnlineUsers();
    setInterval(this.fetchOnlineUsers, 30000); // Refresh every 30 seconds
    this.$nextTick(() => {
      this.scrollToBottom();
      setTimeout(() => {
        this.loadInitialMessages();
        this.room = this.$route.query.room;
        //setInterval(this.fetchMessages, 1000); // Fetch new messages every 5 seconds
        setTimeout(() => {
          this.connectSSE();
        }, 1000);
        const savedUsername = localStorage.getItem('username');
        if (savedUsername) {
          this.username = savedUsername;
          this.isUsernameSet = true;
        }
        const savedPin = localStorage.getItem('pin');
        if (savedPin) {
          this.pin = savedPin;
          this.isPinSet = true;
        }

      }, 500);
    });
    setTimeout(() => {
      const textarea = document.getElementById('myTextarea');
      if (textarea) {
        textarea.addEventListener('keydown', (event) => {
          if (event.keyCode === 13 && !event.shiftKey) { // 按下回车键且未按下 Shift 键
            event.preventDefault(); // 阻止默认的回车换行行为
            this.sendMessage(); // 提交表单
          }
        });
      }

    }, 1000);
    const that = this;

    document.addEventListener('DOMContentLoaded', function () {
      const container = document.querySelector('.messages');
      container.addEventListener('click', function (event) {
        if (event.target.tagName === 'IMG') {
          event.preventDefault();
          const imageUrl = event.target.src;
          that.previewImg = imageUrl;
        }
      });
      // 为父容器添加点击事件监听器
      container.addEventListener('click', function (event) {
        // 检查点击事件的target是否为a标签
        if (event.target.tagName === 'A') {
          // 在新窗口或标签页打开链接
          event.preventDefault(); // 阻止默认的链接行为
          window.open(event.target.href, '_blank');
        }
      });
    });
  },
  updated() {
    //if (this.newScroll) {
    //  const messagesContainer = this.$refs.messages;
    //  const check = messagesContainer.scrollHeight - messagesContainer.scrollTop;
    //  console.log(check);
    //  if (check < 600) {
    //    this.scrollToBottom();
    //  }
    //}

  },
  created() {
    // Add navigation guard to listen for route changes
    this.$router.beforeEach((to, from, next) => {
      // Check if the route name is 'ManageUser'
      if (to.name === 'ManageUser') {
        // If it is, add 'manage-user' class to body
        document.body.classList.add('manage-user');
      } else {
        // Otherwise, remove 'manage-user' class from body
        document.body.classList.remove('manage-user');
      }
      next(); // Continue navigation
    });

  },
  methods: {
    create() {

      if (this.roomname.length > 10) {
        this.roomname = '';
        alert("房间长度不符合规范");
        return false;
      }

      var regex = /^[a-zA-Z0-9]+$/;

      if (regex.test(this.roomname)) {
        console.log("字符串是数字和字母的组合");
      } else {
        this.roomname = '';
        alert("房间名称不符合规范");
        return false;
      }

      this.roomModal = false;
      setTimeout(() => {
        window.location.href = "?room=" + this.roomname
      }, 1000);

    },
    validateMarkdownImages(markdown) {
      // 匹配所有 Markdown 图片语法的正则表达式，捕获 URL 部分
      const regex = /!\[.*?\]\((.*?)\)/g;
      const validExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg'];
      let match;

      while ((match = regex.exec(markdown)) !== null) {
        const url = match[1];
        const extension = url.split('.').pop().toLowerCase();
        if (!validExtensions.includes(extension)) {
          return false;
        }
      }

      return true;
    },
    reName() {
      localStorage.removeItem('username');
      localStorage.removeItem('pin');
      location.reload();
    },
    toggleLink() {
      this.$nextTick(() => {
        const textarea = this.$refs.textarea;
        if (textarea) {
          this.message += '[外部链接](https://这里替换为你引用的链接)';
          textarea.focus();
        }
      });
    },
    toggleImage() {
      this.$nextTick(() => {
        const textarea = this.$refs.textarea;
        if (textarea) {
          this.message += '![image](https://这里替换为外链地址.com)';
          textarea.focus();
        }
      });
    },
    toggleEmojiPicker() {
      if (this.emojiDisplay) {
        this.emojiDisplay = false
        return false
      }
      this.emojiDisplay = true
    },
    addEmoji(event) {
      console.log(event)
      // 从事件对象中获取 emoji 对象
      const emojiObject = event.detail;

      // 获取 emoji 属性的值
      const emoji = emojiObject.emoji;

      // 在 Vue 更新 DOM 之后执行
      this.$nextTick(() => {
        // 获取 textarea 的 DOM 元素
        const textarea = this.$refs.textarea;

        // 在 textarea 的光标位置插入 emoji
        const cursorPos = textarea ? textarea.selectionStart : 0;
        if (textarea) {
          const textBeforeCursor = this.message.substring(0, cursorPos);
          const textAfterCursor = this.message.substring(cursorPos);
          this.message = textBeforeCursor + emoji.unicode + textAfterCursor;

          // 将焦点设置回 textarea
          textarea.focus();
        }
      });
    }
    ,
    fetchOnlineUsers() {
      axios.get(this.$query.url + '/backend/getOnlineUsers')
        .then(response => {
          this.onlineUsers = response.data;
          this.showDialog = false;
        })
        .catch(error => {
          console.error('Error fetching online users:', error);
        });
    },
    goToMessageManagement() {
      this.$router.push('/adminMessageManager');
    },
    loadInitialMessages() {
      this.isLoading = true;
      axios.get(this.$route.query.room == undefined ? this.$query.url + `/backend/getMessages?page=${this.currentPage}&perPage=${this.perPage}` : this.$query.url + `/backend/getMessages?page=${this.currentPage}&perPage=${this.perPage}&room=${this.$route.query.room}`)
        .then(response => {
          if (Array.isArray(response.data)) {
            this.messages = response.data.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
            this.scrollToBottom();

          }
        })
        .finally(() => {
          this.isLoading = false;

        });
    },
    connectSSE() {
      this.isLoading = true;
      // 清除上一次的连接(如果存在)
      if (this.eventSource) {
        this.eventSource.close();
        this.eventSource = null;
      }

      const startTime = performance.now(); // 获取开始时间戳
      const roomQuery = this.$route.query.room ? `?room=${this.$route.query.room}` : '';
      const url = `${this.$query.url}/backend/sseMessages${roomQuery}`;

      const createEventSource = () => {
        this.eventSource = new EventSource(url);

        this.eventSource.onmessage = (event) => {
          const data = JSON.parse(event.data);
          if (Array.isArray(data)) {
            if (data.length > 0) {
              const newMessages = data.filter(newMsg => !this.messages.some(oldMsg => oldMsg.id === newMsg.id));
              // 将新数据附加到现有数据的末尾，并确保没有重复的数据
              this.messages = this.messages.concat(newMessages).sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
              if (this.newScroll) {
                this.scrollToBottom();
              }
            } else {
              if (this.messages.length == 0) {
                this.messages = [{ id: 0, username: "系统提示", timestamp: 1719573511, message: "你已成功创建房间", is_admin: "true" }];
              }
            }
          }
        };

        this.eventSource.onerror = (error) => {
          console.error('EventSource failed:', error);
          this.isLoading = false;
          this.eventSource.close();
          setTimeout(createEventSource, 3000); // 3秒后重新连接
        };

        this.eventSource.onopen = () => {
          this.isLoading = false;
          const endTime = performance.now(); // 获取结束时间戳
          const duration = endTime - startTime; // 计算耗时
          this.ms = `${duration.toFixed(2)}`; // 输出耗时
        };
      };

      createEventSource(); // 创建初始的 EventSource 连接
    },

    fetchMessages() {
      this.isLoading = true;
      // 清除上一次的计时器(如果存在)
      if (this.requestTimer !== null) {
        clearTimeout(this.requestTimer);
        this.requestTimer = null;
      }

      const startTime = performance.now(); // 获取开始时间戳

      axios.get(this.$route.query.room == null ? this.$query.url + '/backend/getMessages' : this.$query.url + '/backend/getMessages?room=' + this.$route.query.room)
        .then(response => {
          if (Array.isArray(response.data)) {
            if (response.data.length > 0) {
              const newMessages = response.data.filter(newMsg => !this.messages.some(oldMsg => oldMsg.id === newMsg.id));
              // 将新数据附加到现有数据的末尾，并确保没有重复的数据
              this.messages = this.messages.concat(newMessages).sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
              if (this.newScroll) {
                this.scrollToBottom();
              }
            } else {
              if (this.messages.length == 0) {
                this.messages = [{ id: 0, username: "系统提示", timestamp: 1719573511, message: "你已成功创建房间", is_admin: "true" }]
              }
            }
          }
        })
        .finally(() => {
          this.isLoading = false;
          const endTime = performance.now(); // 获取结束时间戳
          const duration = endTime - startTime; // 计算耗时

          // 使用setTimeout延迟输出结果,确保在下一次请求开始前输出
          this.requestTimer = setTimeout(() => {
            this.ms = `${duration.toFixed(2)}`; // 输出耗时
            this.requestTimer = null; // 重置计时器引用
          }, 0);

          setTimeout(() => {
            if (this.send == true) {
              this.scrollToBottom();
            }
          }, 500);

        });
    },
    loadMoreMessages() {
      if (this.allMessagesLoaded) return;
      this.currentPage++;
      this.isLoading = true;
      axios.get(this.$route.query.room == null ? this.$query.url + `/backend/getMessages?page=${this.currentPage}&perPage=${this.perPage}` : this.$query.url + `/backend/getMessages?page=${this.currentPage}&perPage=${this.perPage}&room=${this.$route.query.room}`)
        .then(response => {
          if (Array.isArray(response.data)) {
            if (response.data.length > 0) {
              const newMessages = response.data.filter(newMsg => !this.messages.some(oldMsg => oldMsg.id === newMsg.id));
              // 将新数据附加到现有数据的末尾，并确保没有重复的数据
              this.messages = this.messages.concat(newMessages).sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
            } else {
              this.allMessagesLoaded = true;
            }
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleScroll(event) {
      const top = event.target.scrollTop === 0;
      if (top) {
        this.loadMoreMessages();
      }
      const scrollTop = event.target.scrollTop; // 当前滚动位置
      if (scrollTop > this.lastScrollTop) {
        // 下拉
        console.log('下拉');
        const messagesContainer = this.$refs.messages;
        const check = messagesContainer.scrollHeight - messagesContainer.scrollTop;
        console.log(check);
        if (check < 700) {
          this.scrollToBottom();
          this.newScroll = true;
        }
      } else {
        // 上拉
        console.log('上拉');
        this.newScroll = false;
      }
      this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // 更新最后的滚动位置
    },
    setUsername() {
      function hasSpecialCharacters(str) {
        var regex = /[^0-9a-zA-Z\u4e00-\u9fa5]/; // 匹配除了数字、字母、中文以外的字符
        return regex.test(str);
      }
      if (this.username && !hasSpecialCharacters(this.username)) {
        localStorage.setItem('username', this.username);
        this.isUsernameSet = true;
      } else {
        alert('用户名不符合规范');
      }
    },
    checkPin() {
      // 验证 PIN 是否为 6 位数字
      if (/^\d{6}$/.test(this.pin)) {
        // 发送 AJAX 请求到 check 进行验证
        axios.post(this.$query.url + '/backend/check', {
          username: this.username,
          pin: this.pin
        }).then(response => {
          // 根据响应处理结果
          if (response.data.status === 'success') {
            // 如果用户名和 PIN 匹配，则执行设置 cookie 的逻辑
            localStorage.setItem('username', this.username);
            localStorage.setItem('pin', this.pin);
            this.isPinSet = true;
          } else {
            // 如果用户名和 PIN 不匹配，则显示错误消息
            alert('用户名和PIN不匹配请重试');
            localStorage.removeItem('username');
            this.isUsernameSet = false;
            this.isPinSet = false;
          }
        }).catch(error => {
          // 处理请求错误
          console.error('Error occurred during PIN check:', error);
        });
      } else {
        alert('PIN码必须6位数字');
      }
    },
    sendMessage() {
      if (!this.validateMarkdownImages(this.message)) {
        alert('插入的图片不符合规范');
        return false;
      }

      this.send = true;
      this.emojiDisplay = false
      if (this.username && this.pin && this.message) {
        this.isLoading = true;
        axios.post(this.$query.url + '/backend/sendMessage', {
          username: this.username,
          pin: this.pin,
          message: this.message,
          room: this.$route.query.room != null ? this.$route.query.room : ''
        }).then(response => {
          this.scrollToBottom();
          if (response.data.status === 'success') {
            this.message = '';
            //this.fetchMessages();
            this.scrollToBottom();
          } else {
            alert(response.data.message); // 处理失败
          }
        })
          .finally(() => {
            this.isLoading = false;
            this.scrollToBottom();
          });
      } else {
        alert('提交的内容为空，请输入用户名或PIN或消息');
      }
      setTimeout(() => {
        this.send = false;
      }, 10000);
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const messagesContainer = this.$refs.messages;
        if (messagesContainer) {
          setTimeout(() => {
            messagesContainer.scrollTop = messagesContainer.scrollHeight;
          }, 100);
          //setTimeout(() => {
          //  messagesContainer.scrollTop = messagesContainer.scrollHeight;
          //}, 1000);
        }
      });
      this.newScroll = true;
    },
    formatDate(timestamp) {
      const date = new Date(timestamp * 1000); // 将秒数转换为毫秒数
      return date.toLocaleString();
    }
  }
}
</script>
<style>
div {
  -o-transition: all 2s;
  -moz-transition: all 2s;
  -webkit-transition: all 2s;
  transition: all 2s;
  /*无前缀的始终在后面写*/
}

body {
  margin: 0;
  padding: 0;
}

body::before {
  --line: color-mix(in lch, canvasText 25%, transparent);
  --size: 60px;
  content: "";
  height: 100vh;
  width: 100vw;
  position: fixed;
  background: linear-gradient(90deg,
      var(--line) 1px,
      transparent 1px var(--size)) 0 -5vmin / var(--size) var(--size),
    linear-gradient(var(--line) 1px, transparent 1px var(--size)) 0 -5vmin / var(--size) var(--size);
  -webkit-mask: linear-gradient(-15deg, transparent 60%, white);
  mask: linear-gradient(-15deg, transparent 60%, white);
  top: 0;
  z-index: -1;
}

.msg-bubble img {
  width: 100px;
  border: 2px solid #505050;
  border-radius: 10px;
  margin: 0 5px;
  cursor: pointer;
}

.msg-bubble p {
  margin: 0;
}
</style>
<style scoped>
@media screen and (min-width: 100px) and (max-width: 1024px) {
  .chat-container {
    height: 100vh !important;
    border-radius: 0 !important;
    border: none !important;
    /** padding: 1vh 2vh !important; */
    padding: 0;

  }

  .notify {
    text-align: left !important;
    padding: 0 20px;
  }

  .online-users-dialog {
    width: 300px !important;
  }

  emoji-picker {
    bottom: 135px !important;
  }

  .hiddenCreate {
    display: none !important;
  }

  .messages {
    height: 65vh !important;
  }
}


.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  position: fixed;
  z-index: 99999;
  width: 100%;
  background: rgb(0 0 0 / 66%);
}

.image-container img {
  max-width: 100%;
  /* 确保图片最大宽度不超过容器宽度 */
  max-height: 100%;
  /* 确保图片最大高度不超过容器高度 */
  object-fit: contain;
  /* 如果图片比例不符，保持图片完整 */
}

.chat-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 0;
  position: relative;
  flex-flow: column wrap;
  border: 2px solid #ddd;
  border-radius: 5px;
  background: #eee;
  box-shadow: 0 15px 15px -5px rgba(0, 0, 0, 0.2);
}

.loading {
  position: absolute;
  top: 10px;
  width: 10px;
  height: 10px;
  border: 3px solid #ccc;
  border-radius: 50%;
  border-top-color: #007BFF;
  animation: spin-7ba5bd90 1s linear infinite;
  left: 20px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.messages {
  position: relative;
  height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 0px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 0px;
  background-color: #f0f0f0;
  background-image: url(./assets/tm.png);
  background-size: cover;

}

.message {
  margin-bottom: 10px;
}

.message.admin strong {
  color: red;
  font-weight: bold;
}

.timestamp {
  font-size: 0.8em;
  color: #888;
}

.input-area {
  display: flex;
  flex-direction: column;
  position: relative;
}

.input-area textarea {
  height: 35px !important;
}

.input-area input,
.input-area textarea {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 12px;
}

.input-area textarea {
  resize: vertical;
}

.input-area button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #2d85fa;
  background-image: linear-gradient(135deg, #97e5ff 0%, #2d85fa 100%);
  color: #fff;
}

.msg-bubble {
  max-width: 450px;
  padding: 15px;
  border-radius: 15px;
  background: #FFF;
  border-bottom-left-radius: 0 !important;
}

.msg-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.msg-info-name {
  margin-right: 10px;
  font-weight: bold;
}

.msg-info-time {
  font-size: 11px;
  color: #AAA;
}

.right-msg .msg-bubble {
  background: #EFFDDB;
  color: black;
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 0;
}

.right-msg {
  flex-direction: row-reverse;
  display: flex;
  align-items: flex-end;
}

.left-msg {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}

.online-users-dialog {
  width: 558px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-height: 500px;
  position: fixed;
  bottom: -600px;
  /* 初始状态在底部 */
  left: 50%;
  transform: translateX(-50%);
  transition: bottom 0.5s ease-out;
  /* 底部往上滑动的过渡动画 */
  z-index: 999;
}

.online-users-dialog.active {
  bottom: calc(100% - 600px);
  /* 打开时在底部往上滑动到指定高度 */
}

.online-users-dialog button {
  position: absolute;
  top: 10px;
  right: 10px;
}

/* Styles for the table */
table {
  width: 100%;
  border-collapse: collapse;
}

table th,
table td {
  border: 1px solid #ccc;
  padding: 8px;
}

table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

table tr:nth-child(even) {
  background-color: #f9f9f9;
}

table tr:hover {
  background-color: #eaeaea;
}

/* Styles for the button */
button {
  padding: 10px 20px;
  background-color: #579ffb;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:disabled {
  /* 在这里定义禁用状态下的样式 */
  opacity: 0.5;
  cursor: not-allowed;
}

button:hover {
  background-color: #0056b3;
}

.right {
  margin: 0;
  display: flex;
  margin-left: auto;
}

.right button {
  height: 50px;
  width: 50px;
  border-radius: 99px;
}

emoji-picker {
  position: absolute;
  bottom: 115px;
  left: 00px;
}

.talk-action {
  display: flex;
}

.image-toggle,
.link-toggle {
  cursor: pointer;
  padding: 5px;
  background: #ececec;
  border-radius: 10px;
}

.emoji-toggle {
  cursor: pointer;
  padding: 5px;
  background: #ececec;
  border-radius: 10px;
}

.notify {
  font-size: 13px;
  text-align: center;
  color: #4c4c4c;
}

.bottomAction {
  color: rgb(43 43 43);
  text-decoration: none;
  display: block;
  font-size: 12px;
  text-align: right;
}

.rename {
  cursor: pointer;
  font-size: 12px;
}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  padding-top: 60px;
}

.modal-content {
  background-color: #fefefe;
  margin: 5% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 400px;
  border-radius: 8px;
  text-align: center;
}

.close-btn {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close-btn:hover,
.close-btn:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal input {
  width: 60%;
  padding: 10px 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-right: 10px;
}

.modal button {
  background-color: #7289da;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  color: white;
}

.modal button:hover {
  background-color: #677bc4;
}

.bottomAction a {
  border: 1px solid #70757a;
  padding: 5px 10px;
  margin-right: 10px;
  text-decoration: none;
  border-radius: 99px;

}

.chat-footer {
  background: white;
  height: 70px;
  line-height: 70px;
  display: flex;
  justify-content: space-between;

}

.chat-footer button {
  background: unset;
  background-color: unset;
  background-image: unset;
  color: #777;
}

.chat-footer input {
  border: none;
  margin: 0;
}

#send-btn {
  color: black;
}

.chat-footer input {
  width: 60%;
}

input:focus {
  outline: none;
}

input,
textarea {
  font-size: 16px;
  touch-action: manipulation;
}
</style>
