<template>
    <div class="chat-manage">
        <div class="tab">
            <button class="tablinks" @click="openTab('Tab1')"
                :class="this.activeTab == 'Tab1' ? 'active' : ''">聊天室消息管理</button>
            <button class="tablinks" @click="openTab('Tab2')"
                :class="this.activeTab == 'Tab2' ? 'active' : ''">黑名单管理</button>
        </div>
        <div id="Tab1" class="tabcontent" v-show="activeTab === 'Tab1'">
            <div v-if="!isAdmin">
                <p>Access denied. Only admin users can access this page.</p>
            </div>
            <div v-else class="message-list">
                <ul>
                    <li v-for="message in messages" :key="message.id" class="message-item">
                        <p><strong>{{ message.username }}</strong>: <font style="color:red">{{ message.message }}</font>
                        </p>
                        <p>{{ new Date(message.timestamp * 1000).toLocaleString() }}</p>
                        <p>{{ message.ip }}</p>

                        <button @click="deleteMessage(message.id)">删除</button>
                        <button @click="muteUser(message.username)">禁言用户</button>
                        <button @click="muteUser(message.ip)">拉黑IP</button>
                    </li>
                </ul>
                <div class="button-group">
                    <button @click="prevPage" :disabled="currentPage === 1">上一页</button>
                    <span>Page {{ currentPage }} of {{ totalPages }}</span>
                    <button @click="nextPage" :disabled="currentPage === totalPages">下一页</button>
                </div>
            </div>

        </div>
        <div id="Tab2" class="tabcontent" v-show="activeTab === 'Tab2'">
            <table>
                <thead>
                    <tr>
                        <th>Username / IP</th>
                        <th>操作</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in blacklist" :key="index">
                        <td>{{ item.key }}</td>
                        <td><button @click="deleteItem(item.key)">删除</button></td>
                    </tr>
                </tbody>
            </table>
            <div>
                <button @click="prevPageBlackList" :disabled="currentPageBlackList === 1">上一页</button>
                <span>Page {{ currentPageBlackList }} of {{ totalPagesBlackList }}</span>
                <button @click="nextPageBlackList" :disabled="currentPageBlackList === totalPagesBlackList">下一页</button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            messages: [],
            blacklist: [],
            isAdmin: false,
            currentPage: 1,
            totalPages: 1,
            limit: 10,
            currentPageBlackList: 1,
            perPageBlackList: 10,
            totalPagesBlackList: 0,
            activeTab: 'Tab1',
        };
    },
    mounted() {
        document.getElementById("Tab1").style.display = "block";
        this.checkAdmin();
        setInterval(this.fetchMessages, 5000);
        setInterval(this.fetchBlacklist, 5000);
    },
    methods: {
        checkAdmin() {
            // Check if the current user is an admin
            const username = localStorage.getItem('username');
            const pin = localStorage.getItem('pin');
            const that = this;
            // Send a request to check if the user is an admin
            axios.post(this.$query.url + '/backend/checkAdmin', { username, pin })
                .then(response => {
                    if (response.data.status === 'success') {
                        console.log('User is an admin');
                        that.isAdmin = true;
                        that.fetchMessages();
                        this.fetchBlacklist();
                    } else {
                        console.log('User is not an admin');
                        that.isAdmin = false;
                        window.location.href = '/';
                    }
                })
                .catch(error => {
                    console.error('Error checking admin status:', error);
                });
        },
        nextPageBlackList() {
            if (this.currentPageBlackList < this.totalPagesBlackList) {
                this.currentPageBlackList++;
                this.fetchBlacklist();
            }
        },
        prevPageBlackList() {
            if (this.currentPageBlackList > 1) {
                this.currentPageBlackList--;
                this.fetchBlacklist();
            }
        },
        fetchBlacklist() {
            if (!this.isAdmin) return;

            // Fetch blacklist data from backend with pagination
            axios.get(this.$query.url + `/backend/blacklistManage?page=${this.currentPageBlackList}&perPage=${this.perPageBlackList}`)
                .then(response => {
                    this.blacklist = response.data.list;
                    this.totalPagesBlackList = Math.ceil(response.data.total / this.perPageBlackList);
                })
                .catch(error => {
                    console.error('Error fetching blacklist:', error);
                });
        },
        fetchMessages() {
            if (this.isAdmin == false) {
                return false
            }
            axios.get(this.$query.url + `/backend/getMessagesManage?page=${this.currentPage}&limit=${this.limit}`)
                .then(response => {
                    if (response.data.status === 'success') {
                        this.messages = response.data.messages;
                        this.totalPages = response.data.pagination.total_pages;
                    } else {
                        alert('Failed to fetch messages');
                    }
                })
                .catch(error => {
                    console.error('Error fetching messages:', error);
                });
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                this.fetchMessages();
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
                this.fetchMessages();
            }
        },
        deleteMessage(messageId) {
            if (this.isAdmin == false) {
                return false
            }
            axios.post(this.$query.url + '/backend/deleteMessage', { id: messageId })
                .then(response => {
                    if (response.data.status === 'success') {
                        this.fetchMessages();
                    } else {
                        alert(response.data.message);
                    }
                })
                .catch(error => {
                    console.error('Error deleting message:', error);
                });
        },
        deleteItem(username) {
            if (this.isAdmin == false) {
                return false
            }
            var admin = localStorage.getItem('username');
            var pin6 = localStorage.getItem('pin');
            // Confirm deletion and delete item from blacklist
            const confirmDelete = window.confirm('确认?');
            if (confirmDelete) {
                axios.post(this.$query.url + '/backend/blacklistManage', { key: username, adminUsername: admin, pin: pin6 })
                    .then(response => {
                        if (response.data.status === 'success') {
                            // Remove item from blacklist array
                            this.blacklist = this.blacklist.filter(item => item.username !== username);
                        } else {
                            alert(response.data.message);
                        }
                    })
                    .catch(error => {
                        console.error('Error deleting blacklist item:', error);
                    });
            }
        },

        muteUser(value) {
            if (this.isAdmin == false) {
                return false
            }
            axios.post(this.$query.url + '/backend/muteUser', { key: value })
                .then(response => {
                    if (response.data.status === 'success') {
                        this.fetchMessages();
                    } else {
                        alert(response.data.message);
                    }
                })
                .catch(error => {
                    console.error('Error muting user:', error);
                });
        },
        openTab(tabName) {
            this.activeTab = tabName;
        }
    }
};
</script>

<style>
@media screen and (min-width: 100px) and (max-width: 1024px) {
    .manage-user .chat-container {
        width: 100% !important;
        max-width: 100% !important;
        float: unset !important;
    }

    .manage-user .chat-manage {
        width: 100% !important;
        max-width: 100% !important;
        float: unset !important;
        padding: 0 !important;
    }
}


.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

/* 标题样式 */
h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

/* 提示信息样式 */
.access-denied {
    color: red;
    font-weight: bold;
}

/* 消息列表样式 */
.message-list {
    list-style: none;
    padding: 0;
    max-height: 90vh;
    overflow: auto;
}

.message-item {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    background: white;
}

.message-item p {
    margin: 0;
}

.message-item strong {
    font-weight: bold;
}

.timestamp {
    font-style: italic;
}

/* 按钮样式 */
.button-group {
    margin-top: 20px;
}

.button-group button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin-right: 10px;
    cursor: pointer;
}

.button-group button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.manage-user .chat-container {
    margin: auto;
    float: left;
    max-width: 50%;
    width: 40%;
}

.manage-user .chat-manage {
    margin: auto;
    float: right;
    width: 50%;
    padding: 2vh;
    position: relative;
    flex-flow: column wrap;
    height: calc(100vh - 50px);
    border: 2px solid #ddd;
    border-radius: 5px;
    background: #eee;
    box-shadow: 0 15px 15px -5px rgba(0, 0, 0, 0.2);
}

ul,
li {
    list-style: none;
    padding: 0;
    margin: 0;
}

/* 标签样式 */
.tab {
    overflow: hidden;
    border: 1px solid #ccc;
    background-color: #f1f1f1;
}

.tab button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 10px 20px;
    transition: 0.3s;
}

.tab button.active {
    background-color: #ccc;
}

.tabcontent {
    padding: 20px;
    border-top: none;
}

.tabcontent button {
    padding: 5px 10px;
    background-color: #454845;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 5px 5px;
}

.tabcontent table {
    width: 100%;
    text-align: left;
    background: white;
    border: 1px solid #ccc;
    padding: 20px;
}

.tabcontent td {
    border-bottom: 1px solid #ccc;
}
</style>
