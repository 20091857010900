import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import query from "./base.js";

const app = createApp(App);
app.config.globalProperties.$query = query;

app.use(router);
app.mount("#app");
